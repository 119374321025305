<template>
<div>
    <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row>

    <v-container>
        <v-row class="align-center d-flex h-0 pb-20">
            <v-col lg="3" sm="8" class="mx-auto">
                <br />
                <checkmong-home-bar title="ข้อมูลสุขภาพ"></checkmong-home-bar>
            </v-col>
        </v-row>
        <br />
        <v-row class="align-center d-flex h-0 pb-20" style="justify-content: center;">
            <v-col lg="3" sm="8">
                <v-expansion-panels style="border-radius: 10px;">
                    <v-expansion-panel>
                        <v-expansion-panel-header expand-icon="info_outline">
                            <v-row class="">
                                <v-col cols="2">
                                    <v-icon color="purple">
                                        person
                                    </v-icon>
                                </v-col>
                                <v-col cols="10" style="text-align: left;padding-top: 26px;">
                                    <v-row><span class="pb-1">ชัชวาลย์ รัตนโสภา</span></v-row>
                                    <v-row><span style="font-size: 11px;">อัพเดทล่าสุด ณ วันที่ <span class="text-purple">07/08/2565</span></span></v-row>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-divider></v-divider>
                            <br>
                            <v-row>
                                <v-col cols="12" style="padding-left: 28px;">
                                    <v-row><span style="font-size: 11px;">เลขบัตรประจำตัวประชาชน : <span class="text-purple">1499900117853</span></span></v-row>
                                    <v-row><span style="font-size: 11px;">วัน/เดือน/ปี เกิด : <span class="text-purple">07/08/2565</span></span></v-row>
                                    <v-row><span style="font-size: 11px;">เบอร์โทรศัพท์ : <span class="text-purple">0856129836</span></span></v-row>
                                    <v-row><span style="font-size: 11px;">ที่อยู่ : <span class="text-purple">121</span></span></v-row>
                                </v-col>
                            </v-row>
                            <br>
                        </v-expansion-panel-content>

                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>

        <v-row class="align-center d-flex h-0 pb-20" style="justify-content: center;">
            <v-col lg="3" sm="8">
                <v-card style="border-radius: 15px" elevation="1">
                    <v-card-text>
                        <v-row dense style="background-color: #ffffff; border-radius: 10px;">
                            <v-col cols="12">
                                <v-list-item-title>โรคประจำตัว</v-list-item-title>
                                <v-list-item-subtitle>
                                    <li>อ้วน</li>
                                    <li>ไขมันในเลือด</li>

                                </v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="6">
                                <v-list-item-title>อายุ</v-list-item-title>
                                <v-list-item-subtitle>31</v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="6">
                                <v-list-item-title>น้ำหนัก(kg)</v-list-item-title>
                                <v-list-item-subtitle>--</v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="6">
                                <v-list-item-title>ส่วนสูง(cm)</v-list-item-title>
                                <v-list-item-subtitle>--</v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="6">
                                <v-list-item-title>BMI (kg/m2)</v-list-item-title>
                                <v-list-item-subtitle>--</v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center" justify="left" style="padding-top: 15px">
            <v-col lg="3" sm="8" class="mx-auto" style="text-align: left;">
                <v-btn outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
            </v-col>

        </v-row>

        <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
            <v-row class="align-left" justify="left">
                <v-btn outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
            </v-row>
        </v-footer>

    </v-container>
</div>
</template>

<script>
import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";
export default {
    name: "calendar-patient",
    components: {
        CheckmongHomeBar,
    },
    data: () => ({
        activePicker: null,
        date: null,
        menu: false,
        selected_row: "",
        dialog: false,
        arrayEvents: null,
        date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
    }),

    mounted() {},
    watch: {},
    methods: {
        save(date) {
            this.$refs.menu.save(date);
        },
        validate() {
            this.$refs.form.validate();
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #ffffff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #a84192;
}

.v-main__wrap {
    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}

.v-date-picker-table .v-btn.v-btn--active {
    color: #ffffff;
    background-color: #a84192;
}

.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #a84192 !important;
}

.theme--light.v-btn.v-btn--icon {
    color: #a84192 !important;
}

.v-dialog>.v-card>.v-card__text {
    padding: 0 24px 0px;
}
</style>
